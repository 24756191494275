<template>
	<div>

		<div class="swiper">
			<ul class="pic">
				<li v-for="(item, index) in list" :key="index" @click="btnBanner(item)">
					<img v-if="item && item.banner_url" :src="'http://82.157.140.72/test' + item.banner_url" alt="">
				</li>
			</ul>
			<ol class="cricle"></ol>
			<div class="btns">
				<span class="leftbtn" style="float: left; margin-left: 6px;"></span>
				<span class="rightbtn" style="float: right; margin-right: 6px;"></span>
			</div>
		</div>
	</div>
</template>

<script>
import imgIng from '../../../components/common/imgIng.vue'

export default {
	name: '',
	// props: ['bannersArr'],
	props: {
		bannersArr: {
			type: [Array, Number],
			default: () => {
			}
		},
	},
	components: {
		imgIng,
	},
	data() {
		return {
			list: [1,1,1,1]
		}
	},
	created() {
		this.banner()
	},
	mounted() {

	},
	methods: {
		banner() {
			setTimeout(() => {
				this.list = this.bannersArr
			}, 3000)
			window.addEventListener("load", function () {
				//获取元素
				var swiper = document.querySelector(".swiper");
				var pic = swiper.querySelectorAll(".pic li");
				var btns = swiper.querySelector(".btns");
				var cricle = swiper.querySelector(".cricle");
				var leftbtn = swiper.querySelector(".leftbtn");
				var rightbtn = swiper.querySelector(".rightbtn");
				var leftBox = swiper.querySelector(".leftBox");
				var rightBox = swiper.querySelector(".rightBox");

				//清除图片类名
				function clearPicName() {
					for (var i = 0; i < pic.length; i++) {
						pic[i].className = "";
					}
				}

				function leave() {
					btns.style.display = "none";
					timer = setInterval(function () {
						rightbtn.click();
					}, 3000);
				}

				var timer = setInterval(function () {
					rightbtn.click();
				}, 3000);
				swiper.addEventListener("mouseenter", function () {
					btns.style.display = "block";
					clearInterval(timer);
					swiper.removeEventListener("mouseleave", leave);
					swiper.addEventListener("mouseleave", leave);
				});
				//动态生成小圆点
				var lili = "";
				// pic.forEach(function () {
				//   lis += "<li></li>";
				// });
				for (var j = 0; j < pic.length; j++) {
					lili += "<li></li>";
				}
				cricle.innerHTML = lili;
				//获取动态生成的小圆点
				var lis = cricle.querySelectorAll("li");
				// lis[0].className = "_bg";
				//清楚小圆点类名
				function clearCricleName() {
					// lis.forEach(function (ele) {
					//   ele.className = "";
					// });
					for (var i = 0; i < lis.length; i++) {
						lis[i].className = "";
					}
				}
				var pic_index = 0;
				//满足ie的特殊癖好
				var lisArr = [];
				for (var i = 0; i < lis.length; i++) {
					lisArr.push(lis[i]);
				}

				lisArr.forEach(function (ele, i) {
					ele.addEventListener("mouseenter", function () {
						clearCricleName();
						ele.className = "_bg";
						//记住索引值
						var index = i;
						clearPicName();

						//上一张
						i = i == 0 ? lisArr.length : i;
						pic[i - 1].className = "prev";
						//恢复索引
						i = index;

						//当前
						pic[i].className = "now";

						//下一张
						i = i == lisArr.length - 1 ? -1 : i;
						pic[i + 1].className = "next";
						//恢复索引
						i = index;
						pic_index = i;
					});
				});

				//左按钮
				leftbtn.addEventListener("click", function () {
					pic_index--;
					pic_index = pic_index < 0 ? pic.length - 1 : pic_index;

					var index = pic_index;
					clearPicName();

					//上一张;
					pic_index = pic_index == 0 ? pic.length : pic_index;
					pic[pic_index - 1].className = "prev";
					pic_index = index;

					//当前
					pic[pic_index].className = "now";
					clearCricleName();
					lis[pic_index].className = "_bg";

					//下一张
					pic_index = pic_index == pic.length - 1 ? -1 : pic_index;
					pic[pic_index + 1].className = "next";
					pic_index = index;
				});

				//右按钮
				rightbtn.addEventListener("click", function () {
					pic_index++;
					pic_index = pic_index == pic.length ? 0 : pic_index;
					var index = pic_index;
					clearPicName();
					//上一张
					pic_index = pic_index == 0 ? pic.length : pic_index;
					pic[pic_index - 1].className = "prev";
					pic_index = index;
					//当前
					pic[pic_index].className = "now";
					clearCricleName();
					lis[pic_index].className = "_bg";

					//下一张
					pic_index = pic_index == pic.length - 1 ? -1 : pic_index;
					pic[pic_index + 1].className = "next";
				});
			});

		},
		btnBanner(val){
			this.$emit("listBanner",val)
		}
	}
}
</script>

<style scoped>
.swiper {
	width: 98%;
	height: 240px;
	margin: 100px auto 50px;
	position: relative;
}

.swiper .pic {
	width: 100%;
	height: 100%;
	position: relative;
}

.swiper .pic li {
	transition: all 0.8s;
	width: 50%;
	height: 240px;
	position: absolute;
	top: 10px;
	left: 50%;
	margin-left: -27%;
	border-radius: 6px;
	overflow: hidden;
	box-shadow: 1px 1px 4px 1px rgba(0, 0, 0, 0.5);
}

.swiper .pic li img {
	width: 100%;
	height: 100%;
}

.now {
	z-index: 7;
	transform: scale(1.2);
}

.next {
	z-index: 6;
	transform: translateX(300px);
}

.prev {
	z-index: 6;
	transform: translateX(-260px);
}

._bg {
	background-color: rgb(244, 13, 13) !important;
}

.swiper .cricle {
	height: 16px;
	position: absolute;
	bottom: 0;
	left: 50%;
	transform: translateX(-50%);
	display: none;
}

.swiper .cricle li {
	float: left;
	width: 14px;
	height: 14px;
	background-color: #cccccc;
	margin-right: 6px;
	border-radius: 50%;
	box-shadow: 1px 1px 4px rgba(0, 0, 0, 0.5);
}

.swiper .btns {
	display: none;
	position: absolute;
	left: 0;
	top: 50%;
	transform: translateY(-50%);
	width: 100%;
	height: 38px;
	z-index: 9;
}

.swiper .btns .leftbtn,
.swiper .btns .rightbtn {
	height: 100%;
	width: 38px;
	border-radius: 50%;
	background-color: rgba(0, 0, 0, 0.5);
}


.swiper .btns .leftbtn::after,
.swiper .btns .rightbtn::after {
	content: "";
	display: block;
	width: 18px;
	height: 18px;
	border-left: 3px solid #ffffff;
	border-top: 3px solid #ffffff;

}

.swiper .btns .leftbtn::after {
	-webkit-transform: translate(12px, 8px) rotate(-45deg);
	-moz-transform: translate(12px, 8px) rotate(-45deg);
	-o-transform: translate(12px, 8px) rotate(-45deg);
	-ms-transform: translate(12px, 8px) rotate(-45deg);
	transform: translate(12px, 8px) rotate(-45deg);
}

.swiper .btns .rightbtn::after {
	-webkit-transform: translate(4px, 8px) rotate(135deg);
	-moz-transform: translate(4px, 8px) rotate(135deg);
	-o-transform: translate(4px, 8px) rotate(135deg);
	-ms-transform: translate(4px, 8px) rotate(135deg);
	transform: translate(4px, 8px) rotate(135deg);
}
</style>
