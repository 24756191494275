/**
 * 引入 axios  拦截器
 */
 import apiService from '../utils/service'
 import {token} from '../components/js/index'

 /**
  *  接口 api
  */
 export default {
    cates(data) {
         return apiService({
             url: '/cates',
             method: 'get',
             headers:{
                authorization: ` Bearer ${token.token}`
             },
             params: data
         })
         
     },
     banners(data) {
        return apiService({
            url: '/base/getBannerList',
            method: 'post',
            headers: {
                authorization: ` Bearer ${token.token}`
            },
            data: data
        })  
    },
    addBanners(data) {
        return apiService({
            url: '/banners',
            method: 'post',
            headers: {
                authorization: ` Bearer ${token.token}`
            },
            data: data
        })  
    },
    // /home?albumLimit=3&songLimit=6
    home(data) {
        return apiService({
            url: '/home',
            method: 'get',
            headers: {
                authorization: ` Bearer ${token.token}`
            },
            params: data
        })  
    },
    search(data) {
        return apiService({
            url: '/home/search',
            method: 'POST',
            headers: {
                authorization: ` Bearer ${token.token}`
            },
            data: data
        })  
    },



     
 
 }
 
 