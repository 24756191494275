<template>
    <div>
        <div class="middile_ul">
			<div class="middile_li" v-for="(item,index) in all.list" :key='index' @click="btnLink(item)">
				<div class="all_top">
					<div class="middile_hover_img"></div>
					<div class="middile_li_hover"></div>
					<images v-if="item&&item.album_cover" class="middile_img" :src=" 'http://82.157.140.72/test' + (item&&item.album_cover)"></images>
				</div>
				<div class="bot_bottom">
                    <div class="bot_all">
                        <div class="v">
                            <div class="box_li">
                                <images v-if="item&&item.user_info&&item.user_info.user_avatar" class="middile_img" :src=" 'http://82.157.140.72/test' + (item&&item.user_info&&item.user_info.user_avatar)"></images>
                            </div>
                            <span class="s">{{item&&item.user_info&&item.user_info.user_nick_name}}</span>
                        </div>
                        <div class="box_ri">
                            <div class="w">名字{{item.album_author}}</div>
                            <div class="w h"> 标签{{item.album_name}}</div>
                        </div>
                    </div>
				</div>
			</div>
		</div>
    </div>
</template>

<script>
import images from '../../../components/common/imgIng.vue'
export default {
    name: 'index',
    components: {
        images,
    },
    props: {
        listAlbumMusic: {
            type: Object,
            default: () => {
                return {
                    list: "",
                }
            }
        },
    },


    data() {
        return {
            list: "",
        }
    },
    computed: {
        all() {
            let Obj = this.listAlbumMusic
            if (!Obj.list) {
                Obj.list = this.list
            }
            return Obj
        }
    },

    created() {

    },
    mounted() {
    
    },
    methods: {

        btnLink(item){
            this.$emit("btnlistAlbum" , item)
        }

    },
}
</script>

<style scoped>
.all{
    width: 100%;
    overflow: hidden;
    padding-bottom: 70px;
    box-sizing: border-box;
}





.el-carousel__item:nth-child(2n) {
    background-color: #99a9bf;
}

.el-carousel__item:nth-child(2n+1) {
    background-color: #d3dce6;
}
.middile {
    width: 100%;	
}
.title_name{
    color: #000;
    font-size: 28px;
    line-height: 28px;
}
.middile_top {
    width: 100%;
    height: 280px;
    padding-top: 8px;
}
.middile_ul {
    width: 100%;
    display: flex;
    flex-wrap: wrap;
    justify-content: flex-start;
    list-style-type: none;
    margin: 2px auto 65px;
    overflow: hidden;
}
.middile_li {
    width: 19%;
    background-color: #fff;
    margin: 5px auto;
    border-radius: 6px;
    transition: all .6s ease;
    display: flex;
    align-items: center;
}
.middile_url_img{
    width: 70px;
    height: 70px;
    border: 1px solid rgba(255,255,255,0.8);
    float: left;
}
.middile_url_image{
    width: 100%;
    height: 100%;
}
.middile_waveform{
    width: calc(100% - 80px);
    height: 100%;
    float: left;
    margin-left: 4px;
}
.all_top{
    position: relative;
    overflow: hidden;
    border-radius: 6px;
}
.middile_hover_img {
    opacity: 0;
    position: absolute;
    top: 50%;
    left: 50%;
    width: 50px;
    height: 50px;
    margin: -25px 0 0 -25px;
    background: url('../../../assets/play-icon.png') no-repeat;
    transform: scale(2);
    backface-visibility: hidden;
    transition: all .25s ease-out;
    overflow: hidden;
    z-index: 1;
}
.middile_img{
    width: 100%;
    height: 100%;
    border-radius: 6px;
    transform: scale(1);
    transition: all .1s ease;
}
.middile_li:hover .middile_img{
    transform: scale(1.05);
    transition: all .5s ease-out;
}

.middile_li:hover .middile_hover_img{
    opacity: 1;
    transform: scale(1);
    cursor: pointer;
    z-index: 100;
    transition: all .2s ease-out;
}
.middile_li:hover.middile_li{
    box-shadow: 2px 2px 10px #999;
    transition: all .6s ease;
    z-index: 1;
}
.middile_li:hover .middile_li_hover{
    background: rgba(0,0,0,0.6);
    width: 100%;
    height: 100%;
    position: absolute;
    top: 0;
    left: 0;
    z-index: 99;
}
.bot_bottom{
    padding: 8px 4px;
    box-sizing: border-box;
    height: 100%;
}
.box_li{
    width: 28px;
    height: 28px;
    background: rgba(0, 0, 0, .6);
    border-radius: 6px;
    overflow: hidden;
}
.bot_all{
    display: flex;
    justify-content: center;
    align-items: flex-start;
    flex-direction: column-reverse;
    align-content: stretch;
    flex-wrap: nowrap;
    height: 100%;
}
.box_ri{
    flex: 1;
}
.w{
    width: 95px;
    overflow: hidden;
    height: 30px;
}
.h{
    margin-top: 5px;
}
.v{
    display: flex;
    justify-content: center;
    align-items: center;
}
.s{
    margin-left: 2px;
}



@media screen and (max-width: 1920px) {
    .middile_li {
        width: 13.7%;

    }
}
@media screen and (max-width: 1650px) {
    .middile_li {
        width: 16%;
    }
}
@media screen and (max-width: 1475px) {
    .middile_li {
        width: 19%;
    }
}
@media screen and (max-width: 999px) {
    .middile_li {
        width: 24%;
    }
}
@media screen and (max-width: 800px) {
    .middile_li {
        width: 32%;
    }
}
</style>