<template>
	<div class="box">
		<left :left="left">
			<tabbar :tabbar="tabbar" @tabbarId="tabbarId"></tabbar>
			<!-- <div class="qb" @click="btnAll()">全部</div> -->
		</left>
		<banners :bannersArr="bannersArr" @listBanner="listBanner"></banners>
		<h6>推荐</h6>
		<listMusic :listMusic="listMusic" @btnId="btnId"></listMusic>
		<h6>专辑</h6>
		<listAlbumMusic :listAlbumMusic="listAlbumMusic" @btnlistAlbum="btnlistAlbum"></listAlbumMusic>
		<loading :loading="loading"></loading>
	</div>
</template>

<script>
import left from '../../components/common/index/left.vue'
import api from '../../api/index'
import playtr from '../play/components/wave.vue'
import banners from './components/banner.vue'
import tabbar from './components/tabbar.vue'
import listMusic from './components/listMusic.vue'
import listAlbumMusic from './components/listAlbumMusic.vue'
import loading from '../../components/common/loading.vue'
export default {
	name: 'index',
	components: {
		playtr,
		banners,
		left,
		tabbar,
		listMusic,
		listAlbumMusic,
		loading
	},

	data() {
		return {
			left:{
				isbg:""
			},
			bannersArr:[],
			listAll: "",
			datalist: {
				url: '',
				color: "green",
				clientWidths: 500,
				clientHeights: 50,
			},

			tabbar:{
				lists:[],
			},
			listMusic:{
				list:[],
			},
			listAlbumMusic:{
				list:[]
			},
			musicList:{
				
			},
			loading:{
				isShow:false
			}
		}
	},

	created() {

	},
	mounted() {
		this.catesFun()
		this.bannersFun()
		this.homeFun()
	},
	methods: {
		homeFun(){
			var obj ={
				songLimit:18,
				albumLimit:30,
			}
			this.loading.isShow= true
			api.home(obj).then((res) => {
				if (res.data.code == 200) {
					// console.log(res.data,9999)
					this.listMusic.list=res.data.result.songsList
					this.listAlbumMusic.list=res.data.result.albumList
					this.loading.isShow= false
				}
			})
		},

		bannersFun(){
			var obj ={
				banner_type: "INTRANET", 
				banner_pos: "MAIN" ,
				pageNum:1,
				pageSize:100
			}
			api.banners(obj).then((res) => {
				if (res.data.code == 200) {
					// console.log(res.data.result,99999999)
					this.bannersArr = res.data.result
				}
			})
		},
		catesFun() {
			api.cates().then((res) => {
				if (res.data.code == 200) {
					// console.log(res.data,9999)
					this.tabbar.lists = res.data.result
				}
			})
		},
		btnId(v){
			// console.log(v,"id");
			// if(this.$store.state.login){
				// this.$router.push({path: '/login'})
			// } else {
				var en = btoa(v.id);
				this.$router.push({path: '/play',query: {id: en}})
			// }
		},
		// list
		tabbarId(id){
			// console.log(id.id);
			this.loading.isShow= true
			this.btnListFun(id.id)
		},
		// list album
		btnlistAlbum(id){
			// console.log(id);
			if(!this.$store.state.login){
				this.$router.push({path: '/login'})
			} else {
				var en = btoa(id.id);
				this.$router.push({path: 'album', query: {id: en} })
			}
		},
		btnAll(){
			this.btnListFun()
		},
		// search
		btnListFun(id){
			var obj = {
				song_cate_id: id, 
				keywords: "", 
				pageNum: 1, 
				pageSize: 18
			}
			api.search(obj).then((res) => {
				if (res.data.code == 200) {
					this.loading.isShow= false
					this.listMusic.list=res.data.result.list
				}
			})
		},
		listBanner(val){
			// 跳转类型。 INTRANET =内网，XTRANET= 外网
			
			if(!this.$store.state.login){
				this.$router.push({path: '/login'})
			} else {
				if(val.banner_type=='INTRANET'){
					var en = btoa(val.banner_link_id);
					this.$router.push({path: '/album',query: {id: en}})
				} 
			}
		

		}

	},
}
</script>

<style scoped>
.box {
	margin-left: 214px;
	margin-top: 60px;
}
.qb{
	color: #aaaeb9;
	cursor: pointer;
	line-height: 28px;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    float: left;
    margin: 2px 3px 2px 0;
    width: 60px;
    height: 28px;
    color: #aaaeb9;
    text-align: center;
    background-color: #38393e;
    cursor: pointer;
	margin-top: 12px;
}
h6{
	font-size: 16px;
	margin-top: 16px;
}
@media screen and (max-width: 1340px) {
    .box {
        overflow: hidden;
    }
}

</style>