<template>
    <div>
        <div class="all">
            <div class="list" v-for="(item, index) in all.list" :key="index" @click="btnlist(item)">
                <div class="ul">
                    <div class="imgs">
                        <div class="left_img">
                            <div class="hover_img">
                                <imgIng v-if="item && item.song_cover" class="poimg"
                                    :src="'http://82.157.140.72/test' + (item && item.song_cover)"></imgIng>
                            </div>
                            <img class="img" src="../../../assets/play_logo.png" />
                        </div>
                    </div>
                    <div class="ris">
                        <div class="link"  :style="{background:item.song_colors}"></div>
                        <canvas class="canvas"></canvas>
                        <div class="left_ov">
                            <div class="music_name">作者:<span class="music_span">{{ item.song_author }}</span></div>
                            <div class="music_name">歌曲:<span class="music_span">{{ item.song_name }}</span></div>
                            <div class="music_name">点击:<span class="music_span">{{ item.song_clk_num }}</span></div>
                            <div class="music_name">播放量:<span class="music_span">{{ item.song_play_num }}</span></div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import imgIng from '../../../components/common/imgIng.vue'
import {dynamicColor} from '../../../components/js/index'
export default {
    name: 'index',
    components: {
        imgIng
    },
    props: {
        listMusic: {
            type: Object,
            default: () => {
                return {
                    list: "",
                }
            }
        },
    },

    data() {
        return {
            list: "",
        }
    },
    watch: {
        "all.list"(v) {
            this.dh()
        }
    },
    computed: {
        all() {
            let Obj = this.listMusic
            if (!Obj.list) {
                Obj.list = this.list
            }
            return Obj
        }
    },

    created() {

    },
    mounted() {
        this.dh()
    },
    methods: {
        dynamicColorFun(color){
            return dynamicColor(color)
        },
        btnlist(data) {
            this.$emit("btnId", data)
        },
        dh() {
            // function random(min, max) {
            //     return Math.floor(Math.random() * (max - min)) + min;
            // };
            // var a = []
            // for (var i = 1; i < 2000; i++) {
            //     a.push(random(-0.9, 4))
            // }
            setTimeout(() => {
                for (let ii = 0; ii < this.all.list.length; ii++) {

                    drawLineToContext(this.all.list[ii].song_sound_wave.split(","), 2.5, 50, 25, 0, 3, this.all.list[ii].song_colors)
                    // console.log(this.all.list[ii].song_colors,4444);
                    function drawLineToContext(peaks, absmax, halfH, offsetY, start, end, colors) {
                        var canvas = document.getElementsByClassName('canvas')[ii];
                        canvas.width = 600;
                        canvas.height = halfH * 1.3
                        const ctx = canvas.getContext('2d')
                        var length = peaks.length / 2;
                        var first = Math.round(length * start);
                        var last = Math.round(length * end) + 1;
                        var canvasStart = first;
                        var canvasEnd = last;
                        var scale = canvas.width * 2.9 / (canvasEnd - canvasStart - 1);
                        var halfOffset = halfH + offsetY;
                        var absmaxHalf = absmax / halfH;
                        ctx.beginPath();
                        ctx.moveTo((canvasStart - first) * scale, halfOffset);
                        ctx.lineTo((canvasStart - first) * scale, halfOffset - Math.round((peaks[2 * canvasStart] || 0) / absmaxHalf));
                        var i, peak, h;
                        for (i = canvasStart; i < canvasEnd; i++) {
                            peak = peaks[2 * i] || 0;
                            h = Math.round(peak / absmaxHalf);
                            ctx.lineTo((i - first) * scale + 0, halfOffset - h);
                        }
                        var j = canvasEnd - 1;
                        for (j; j >= canvasStart; j--) {
                            peak = peaks[2 * j + 1] || 0;
                            h = Math.round(peak / absmaxHalf);
                            ctx.lineTo((j - first) * scale + 0, halfOffset - h);
                        }
                        ctx.fillStyle = '#fff'
                        ctx.fillRect(0, 0, 700, halfH * 2)
                        ctx.lineTo((canvasStart - first) * scale, halfOffset - Math.round((peaks[2 * canvasStart + 1] || 0) / absmaxHalf));
                        ctx.closePath();
                        ctx.fillStyle = colors
                        ctx.fill();
                    }
                }
            }, 100)
        }
    },
}
</script>

<style scoped>
.all {
    width: 100%;
    display: flex;
    flex-wrap: wrap;
}

.ul {
    width: 100%;

    display: flex;
    position: relative;
}

.list {
    width: 33%;
    background-color: #fff;
    margin: 5px auto;
    border-radius: 6px;
    transition: all .6s ease;
    box-shadow: 0 0 6px rgb(0 0 0 / 6%);

}

.imgs {
    width: 60px;
    height: 60px;
    float: left;
    position: relative;
    overflow: hidden;
}

.ris {
    width: calc(100% - 66px);
    height: 40px;
    position: relative;
    margin-left: 2px;
    padding-left: 4px;
}

.canvas {
    width: 100%;
    height: 36px;
    background-color: #fff;
    margin: 18px auto 0px;
    border-radius: 6px;
    transition: all .6s ease;
}



.left_img {
    width: 60px;
    height: 60px;
    float: left;
    position: relative;
    border-radius: 4px;
    overflow: hidden;
}

.hover_img {
    width: 100%;
    height: 100%;
    position: absolute;
    left: 0;
    top: 0;
    background: rgba(0, 0, 0, .6);
}

.img {
    width: 50%;
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%)scale(1.2);
    transition: all .4s ease;
    opacity: 0;
}

.list:hover .img {
    opacity: 1;
    transform: translate(-50%, -50%)scale(1);
    transition: all .3s ease;
    cursor: pointer;
}

.poimg {
    width: 100%;
}


.left_ov {
    width: 100%;
    overflow: hidden;
    display: flex;
    position: absolute;
    left: 0;
    top: 0;
}
.link{
    width: calc(100% - 6px);
    height: 1px;
    position: absolute;
    bottom: -15px;
}

.music_name {
    font-size: 12px;
    color: #444;
    margin-bottom: 5px;
    flex: 1;
    margin-top: 3px;
    margin-left: 2px;
}
</style>