<template>
	<div>
		<div class="scroll-bar">
			<div v-for="(item,index) in all.lists" :key='index' >
				<slot>
					<div class="left_name">{{item.name}}</div>
					<div class="left_ul">
						<div class="left_li" v-for="(son,index) in item.list" :key='index' @click="btnList(son)">
							{{son.category_name}}
						</div>
					</div>
				</slot>
			</div>
		</div>
	</div>
</template>

<script>

export default {
	name: 'index',
	components: {


	},


	props: {
		tabbar: {
			type: Object,
			default: () => {
				return {
					lists: "", // 
				}
			}
		},
	},
	data() {
		return {
			lists: '', // 
		}
	},
	computed: {
		all() {
			let Obj = this.tabbar
			if (!Obj.lists) {
				Obj.lists = this.lists
			}
			return Obj
		}
	},

	created() {

	},
	mounted() {

	},
	methods: {
		btnList(val){
			this.$emit("tabbarId" , val)
		}

	},
}
</script>

<style scoped>
.left {
	width: 212px;
	background: #2f3035;
	position: fixed;
	left: 0;
	top: 59px;
	bottom: 0px;
	padding: 10px 2px 10px 10px;
	box-sizing: border-box;
	z-index: 1;
}
.left_name {
	color: #aaaeb9;
	margin-top: 6px;
	margin-bottom: 2px;
}

.left_ul {
	overflow: hidden;
}

.left_li {
	line-height: 28px;
	overflow: hidden;
	text-overflow: ellipsis;
	white-space: nowrap;
	float: left;
	margin: 2px 3px 2px 0;
	width: 60px;
	height: 28px;
	color: #aaaeb9;
	text-align: center;
	background-color: #38393e;
	cursor: pointer;
}



/*自定义 滚动条 start*/
.scroll-bar {
  width: 100%;
  height: 100%;
  overflow: auto;
}
.scroll-bar::-webkit-scrollbar {
  width: 4px;
  height: 2px;
}
/*正常情况下滑块的样式*/
.scroll-bar::-webkit-scrollbar-thumb {
  background-color: #d2ecd6;
  border-radius: 8px;
  -webkit-box-shadow: inset 1px 1px 0 rgba(0, 0, 0, .1);
}
/*鼠标悬浮在该类指向的控件上时滑块的样式*/
.scroll-bar:hover::-webkit-scrollbar-thumb {
  background: linear-gradient(180deg, rgba(1, 231, 207, .6) 0%, rgba(69, 142, 255, .6) 100%);
  border-radius: 8px;
  -webkit-box-shadow: inset 1px 1px 0 rgba(0, 0, 0, .1);
}
/*鼠标悬浮在滑块上时滑块的样式*/
.scroll-bar::-webkit-scrollbar-thumb:hover {
  background: linear-gradient(180deg, rgba(1, 231, 207, .6) 100%, rgba(69, 142, 255, .6) 0%);
  -webkit-box-shadow: inset 1px 1px 0 rgba(0, 0, 0, .1);
}
/*正常时候的主干部分*/
.scroll-bar::-webkit-scrollbar-track {
  border-radius: 5px;
  background-color: #2f3035;
}
/*鼠标悬浮在滚动条上的主干部分*/
.scroll-bar::-webkit-scrollbar-track:hover {
  -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0);
  background-color: #2f3035;
}
/*自定义 滚动条 end*/

</style>